<template>
  <div class="p-4">
    <CRow>
      <CCol sm="2" />
      <CCol sm="10" lg="6">
        <CInput v-model="cate1" label="구분 1" placeholder="카테고리 1 이름" horizontal />
        <CInput v-model="cate2" label="구분 2" placeholder="사용하지 않음" horizontal />
        <CInput v-model="cate3" label="구분 3" placeholder="사용하지 않음" horizontal />
        <CInput v-model="cate4" label="구분 4" placeholder="사용하지 않음" horizontal description="사용하지 않는 경우 빈 칸으로 입력하십시오." />
      </CCol>
    </CRow>
    <CRow class="justify-content-end mx-2">
      <CButton class="mr-1" color="secondary" @click="onCancel">
        <CIcon name='cil-x' /><span class="ml-1">취소</span>
      </CButton>
      <CButton class="mr-1" color="primary" @click="onSave">
        <CIcon name='cil-save' /><span class="ml-1">저장</span>
      </CButton>
    </CRow>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'FreightCate',
  data() {
    return {
      cate1: null,
      cate2: null,
      cate3: null,
      cate4: null
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData: function() {
      axios.get(`/api/delivery/freight/cates/`)
        .then(result => {
          const cates = result.data.cate;

          this.cate1 = cates.find(el => el.cate_order === 1);
          if (this.cate1) {
            this.cate1 = this.cate1.name;
          }

          this.cate2 = cates.find(el => el.cate_order === 2);
          if (this.cate2) {
            this.cate2 = this.cate2.name;
          }

          this.cate3 = cates.find(el => el.cate_order === 3);
          if (this.cate3) {
            this.cate3 = this.cate3.name;
          }

          this.cate4 = cates.find(el => el.cate_order === 4);
          if (this.cate4) {
            this.cate4 = this.cate4.name;
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    onSave: function() {
      let payload = [];

      if (!this.cate1) {
        if (this.cate2 || this.cate3 || this.cate4) {
          this.$emit('msg', {title: '확인', color: 'warning', msg: '상위 카테고리를 설정하면 하위 카테고리를 설정할 수 있습니다'});
          return;
        }
      } else if (!this.cate2) {
        if (this.cate3 || this.cate4) {
          this.$emit('msg', {title: '확인', color: 'warning', msg: '상위 카테고리를 설정하고 하위 카테고리를 설정할 수 있습니다'});
          return;
        }
      } else if (!this.cate3) {
        if (this.cate4) {
          this.$emit('msg', {title: '확인', color: 'warning', msg: '상위 카테고리를 설정하고 하위 카테고리를 설정할 수 있습니다'});
          return;
        }
      }

      if (this.cate1) payload.push({cate_order: 1, name: this.cate1});
      if (this.cate2) payload.push({cate_order: 2, name: this.cate2});
      if (this.cate3) payload.push({cate_order: 3, name: this.cate3});
      if (this.cate4) payload.push({cate_order: 4, name: this.cate4});

      axios.put(`/api/delivery/freight/cates/`, payload)
        .then(result => {
          this.$emit('updated', [this.cate1, this.cate2, this.cate3, this.cate4]);
          this.$emit('msg', {title: '완료', color: 'success', msg: '골재종류 카테고리가 업데이트 되었습니다'});
        })
        .catch(error => {
          console.error(error);
          onCancel();
          this.$emit('msg', {title: '오류', color: 'danger', msg: '카테고리 변경이 실패했습니다'});
        });
    },
    onCancel: function() {
      this.getData();
    }
  }
}
</script>
